@import '../../../../style/global.scss';

.contentWrapper {
  background: url('../../../../assets/images/map/bg_modal_hotspot.jpg') no-repeat;
  background-position: center bottom;
  background-size: 100% 476px;
}

.mediaContent {
  width: 100%;
  height: 375px;
  min-height: 375px;
  position: relative;

  img {
    width: 100%;
    filter: grayscale(1);
    animation: greyscale 2s ease forwards 1s;
    height: 100%;
    object-fit: cover;
  }

  @keyframes greyscale {
    from {
      filter: grayscale(1);
    }
    to {
      filter: grayscale(0);
    }
  }

  video {
    width: 100%;
    height: 100%;
  }

  .playButton {
    background: url('../../../../assets/images/map/play_icon.png') no-repeat;
    background-size: 100% auto;
    width: 59px;
    height: 60px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    pointer-events: none;
    z-index: 10;
  }
}

.copyWrapper {
  text-align: left;
  padding: 20px 40px 0;
  position: relative;
  z-index: 1;
}

.pageWrapper {
  display: inline-block;
  font-family: Roboto Mono, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0 50px 0 20px;
  text-align: right;
  position: relative;
  width: 25px;
  height: 25px;
  color: rgba(0, 0, 0, 0.7);

  &::after {
    content: '';
    height: 1px;
    width: 27px;
    background: #282828;
    transform: rotate(-45deg);
    transform-origin: center center;
    position: absolute;
    left: 0;
    top: 50%;
  }

  span {
    position: absolute;

    &:first-of-type {
      top: 0;
      left: 0;
      transform: translate(-100%, 0);
    }

    &:last-of-type {
      bottom: 0;
      right: 0;
      transform: translate(100%, 0);
    }
  }
}

.captionWrapper {
  transform: rotate(-90deg) translate(-100%, 0);
  transform-origin: left top;
  position: absolute;
  left: 0;
  height: 60px;
  display: flex;
  align-items: center;
  font-family: Roboto Mono, sans-serif;
  font-size: 12px;
  text-transform: uppercase;

  &.darkPinCaption {
    span.showType,
    span.storyType,
    span.cityType {
      &::before {
        background: url('../../../../assets/images/map/skull.png') no-repeat;
        width: 44px;
        background-size: 16px auto;
        background-position: 3px 4px;
      }
    }
  }

  span {
    position: relative;
    color: black;

    &::before {
      content: '';
      width: 28px;
      height: 28px;
      border-radius: 14px;
      position: absolute;
      left: -5px;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    &.showType {
      &::before {
        background: rgba($colorPrimaryBlue, 0.6);
      }
    }

    &.storyType {
      &::before {
        background: $colorPrimaryTurquoise;
      }
    }

    &.cityType {
      &::before {
        background: $colorPrimaryMagenta;
      }
    }
  }
}

.titleWrapper {
  padding: 20px 0 30px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 0;
    background: rgba(#282828, 0.4);
    animation: lineToRight 0.3s ease forwards 0.5s;
  }

  .title {
    font-family: Cormorant, serif;
    font-size: 48px;
    line-height: 52px;
    font-weight: 600;
    display: inline-block;
    color: black;

    span {
      text-transform: uppercase;
    }

    i {
      font-style: italic;
    }
  }
}

@keyframes lineToRight {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.descriptionWrapper {
  min-height: 140px;
  padding: 30px 0 0 60px;
  position: relative;
  font-family: Roboto Mono, sans-serif;
  font-size: 12px;
  line-height: 20px;
  color: black;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 60px;
    height: 0;
    width: 1px;
    background: rgba(#282828, 0.4);
    animation: lineDesc 0.2s ease forwards 0.7s;
  }

  @keyframes lineDesc {
    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  }

  p {
    padding: 0 0 0 40px;

    a {
      position: relative;
      transition: color 0.2s ease;
      pointer-events: all;

      div {
        pointer-events: none;
      }

      &:hover {
        color: $colorPrimaryMagenta !important;
      }

      &:before {
        content: '';
        background: $colorPrimaryMagenta;
        width: 0;
        height: 1px;
        position: absolute;
        bottom: -5px;
        left: 0;

        animation: lineLink 0.5s ease forwards 2.2s;

        @keyframes lineLink {
          to {
            width: 100%;
          }
        }
      }
    }
  }
}

.buttonsWrapper {
  margin: 0 40px 20px;
  padding: 30px 0 20px;
  display: flex;
  flex-direction: row;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 1px;
    width: 0;
    background: rgba(#282828, 0.4);
    animation: lineToLeft 0.3s ease forwards 0.5s;
  }

  &::after {
    content: '';
    height: 0;
    width: 1px;
    background: rgba(#282828, 0.4);
    position: absolute;
    left: 50%;
    top: 0;
    animation: lineToBottom 0.3s ease forwards 0.7s;
  }

  @keyframes lineToLeft {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes lineToBottom {
    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  }

  .buttonWrapper {
    flex: 1;
    color: #282828;

    button {
      user-select: none;
      position: relative;
      color: #282828;

      // &:hover {
      //   text-shadow: 0 0 7px white;
      // }

      span {
        font-family: Roboto Mono, sans-serif;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        position: relative;
        opacity: 0;
        animation: buttonsAppear 0.2s ease forwards 1.2s;
        pointer-events: none;
        padding: 0 30px;

        &::after {
          content: '';
          position: absolute;
          width: 15px;
          height: 15px;
          background: url('../../../../assets/images/map/arrow_left.png') no-repeat;
          background-size: 100% auto;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
        }
      }

      @keyframes buttonsAppear {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 1px;
        top: 50%;
        right: 0;
        transform: translate(calc(100% - 10px), 0);
        background: rgba(#282828, 0.4);
        animation: lineToTop 0.3s ease forwards 0.7s;
      }

      @keyframes lineToTop {
        from {
          width: 0;
        }
        to {
          width: calc(335px - 100% - 20px);
        }
      }
    }

    &:last-of-type {
      text-align: right;

      button {
        &::before {
          right: inherit;
          left: 0;
          transform: translate(calc(-100% + 10px), 0);
        }

        span {
          &::after {
            left: inherit;
            right: 0;
            transform: translate(0, -50%) rotate(180deg);
          }
        }
      }
    }
  }
}
