@import '../../style/global.scss';

.PostcardDownloader {
  //   height: 500px;
  position: relative;
}

.postcardWrapper {
  position: absolute;
  top: 190px;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.6);
  z-index: 1;

  @include mediaMin768 {
    top: 260px;
    transform: translate(-50%, -50%);
  }
}

.canvasWrapper {
  top: 29vh;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 63vh;
  // height: 39.9vh;
  position: absolute;
  opacity: 0;

  > * {
    width: 100%;
  }
}

.infoWrapper {
  padding: 150px 20px 10px;
  background: url('../../assets/images/postcards/modal_postcard_bg.jpg') no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
  height: auto;

  @include mediaMin768 {
    padding: 70px 30px 30px;
  }

  .titleWrapper {
    .title {
      font-family: Cormorant, serif;
      font-size: 6.9vw;
      font-weight: 600;
      padding: 0 0 3vh;
      position: relative;

      @include mediaMin768 {
        letter-spacing: -1px;
        font-size: 53px;
        padding: 0 0 20px 94px;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          background: url('../../assets/images/postcards/postcard_icon.png') no-repeat;
          background-size: 100% auto;
          left: 1vh;
          width: 5vh;
          height: 6vh;
          transform: translate(0, -50%);
        }
      }

      &::before {
        content: '';
        height: 1px;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.descriptionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include mediaMin768 {
    align-items: flex-end;
  }

  .copyWrapper {
    font-family: Roboto Mono, sans-serif;
    font-size: 12px;
    padding: 3vh 3vh 2vh 0;
    border-right: 1px solid rgba(black, 0.3);

    @include mediaMin768 {
      padding: 30px 30px 10px;
    }
  }

  a {
    font-family: Roboto Mono, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    padding: 1vh 5vh 0 3vh;
    position: relative;

    @include mediaMin768 {
      padding: 30px 30px 10px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      background: url('../../assets/images/map/arrow_left.png') no-repeat;
      background-size: 100% auto;
      top: calc(50% + 10px);
      right: 0;
      transform: translate(0, -50%) rotate(-90deg);
    }
  }
}
