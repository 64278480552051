@import 'global';
@import 'type/type';
@import 'base/base';

.texture {
  mask-size: 600px auto;
  -webkit-mask-image: url('../assets/images/grunge.png');
  -o-mask-image: url('../assets/images/grunge.png');
  -moz-mask-image: url('../assets/images/grunge.png');
  -ms-mask-image: url('../assets/images/grunge.png');
  mask-image: url('../assets/images/grunge.png');
}

.lineScape:nth-child(2) {
  position: relative;
  top: -20px;
}

.lineScape:nth-child(3) {
  position: relative;
  top: -40px;
}

.isNumber {
  font-family: Spectral, serif;
  font-weight: 500;
}
