.imageComparer {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .image {
    height: 100%;
  }

  .imageComparerWrapper {
    > div {
      height: 250px !important;
    }
  }

  .handle {
    width: 18px;
    height: 32px;
    background: url('../../../../assets/images/map/scroll_ind.png') no-repeat;
    transform: rotate(-90deg);
    background-size: 16px auto;
    position: relative;
    z-index: 2;
  }
}

.hiddenImageWrapper {
  position: absolute;
  top: 20px;
  left: 20px;
  height: calc(100% - 40px);
  width: 50%;
  overflow: hidden;

  img {
    width: 200%;
    height: 100%;
  }
}
