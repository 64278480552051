@import '../../../style/global.scss';

.lineWrapper {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 92%;
  height: 100%;
  margin: 0 4%;
  pointer-events: none;
}

.line {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: $colorWhite;
  opacity: 0.15;

  &:first-child {
    left: 0;
  }

  &:last-child {
    right: 0;
  }

  &:nth-child(2) {
    left: 25%;
  }

  &:nth-child(3) {
    left: 50%;
    transform: translateX(-50%);
  }

  &:nth-child(4) {
    right: 25%;
  }
}

.mapDesktop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
}

.mapMobile {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background: black;
}
