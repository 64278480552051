.carouselWrapper {
  height: 100%;
  position: relative;

  .draggableWrapper {
    height: 100%;
    display: flex;
    flex-direction: row;

    img {
      object-fit: cover;
    }
  }

  .buttonGroup {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;

    button {
      height: 16px;
      width: 16px;
      margin: 0 10px;
      background: url('../../../../assets/images/map/dots.png') right center no-repeat;
      background-size: auto 16px;
      text-indent: -9999px;
      filter: invert(1);

      &.active {
        background-position: left center;
      }
    }
  }

  .navigationButton {
    position: absolute;
    top: 0;
    width: 45%;
    height: 100%;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.hide {
      display: none;
    }
  }
}
