@import '../../../../style/global';

.slide {
  width: 100%;
  color: $colorBlack;
}

.content {
  display: flex;
  flex-direction: column;

  @include mediaMin1024 {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.detail {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;

  @include mediaMin1024 {
    flex-direction: column;
    margin: 0 30px;
  }

  @include mediaMin1600 {
    margin: 0 60px;
  }
}

.pagination {
  position: relative;
  width: 45px;

  @include mediaMin768 {
    margin-bottom: 20px;
    height: 30px;
  }
}

.current {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.lineWrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  overflow: hidden;
  display: block;
  width: 1px;
  height: 100%;
}

.line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: $colorBlack;
}

.total {
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.descriptionWrapper {
  margin-left: 20px;
  overflow: hidden;

  @include mediaMin1024 {
    margin-left: 0;
  }

  @include mediaMin1240 {
    width: 135px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  line-height: 1;

  span {
    text-transform: uppercase;
  }

  @media (max-width: 481px) {
    font-size: 25px;
  }
}

.card {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 4% 8% 4% 5%;
  background: #989693 url(../../../../assets/images/extras/card-texture.jpg) no-repeat center;
  background-size: cover;
  background-blend-mode: multiply;
  border-radius: 14px;
  box-shadow: 5px 15px 50px 0px rgba(0, 0, 0, 0.5);
  transition: background-color 0.4s ease-out;
  pointer-events: all;

  > * {
    pointer-events: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 10px;
    border: 1px solid #d8b55e;
  }

  @include mediaMin768 {
    box-shadow: 5px 15px 100px 0px rgba(0, 0, 0, 0.5);
  }

  &.isActive {
    background-color: #c2b8a3;
  }
}

.image {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding-bottom: (436 / 726) * 100%; // (height / width) * 100%
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset -3px 6px 10px 0px $colorBlack;
}

.duration {
  position: absolute;
  bottom: 4%;
  right: 4.5%;
  transform: rotate(-90deg) translateX(120%);
  transform-origin: bottom right;
  z-index: 1;
  text-transform: uppercase;
  color: $colorWhite;
}

.titleWrapper {
  overflow: hidden;
  margin-top: -9%;
}

.title {
  position: relative;
  z-index: 1;
  display: inline-flex;
  flex-direction: column;
  font-family: Cormorant, serif;
  font-size: 13vw;
  font-weight: 600;
  font-style: italic;
  line-height: 1;
  color: $colorBlack;
  transition: color 0.4s ease-out;

  ::selection {
    color: $colorBlack;
  }

  @include mediaMin768 {
    font-size: 11vw;
  }

  @include mediaMin1024 {
    font-size: 9vw;
  }

  @include mediaMin1240 {
    font-size: 8.5vw;
  }

  @include mediaMin1600 {
    font-size: 8vw;
  }

  @include mediaMin1920 {
    font-size: 160px;
  }

  &.isActive {
    color: $colorPrimaryTurquoise;
  }
}
