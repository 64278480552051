@import '../../../../style/global.scss';

.about {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $colorWhite;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../../../assets/images/landing/about/background-mobile.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include mediaMin768 {
    background-image: url(../../../../assets/images/landing/about/background.jpg);
  }
}

.backgroundOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: rgba($colorBlack, 0.7);
  pointer-events: none;
}

.linkOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.wrapper {
  pointer-events: none;
  position: relative;
  z-index: 1;
  width: 92%;
  height: 100%;
  margin: 0 auto;
  padding: 80px 0 50px;
  opacity: 0;

  @media (max-width: 320px) {
    padding-top: 80px;
  }

  @include mediaMin768 {
    padding: 120px 0 10%;
  }

  @include mediaMin1024 {
    padding: 120px 0 60px;

    @include device-mobile {
      padding: 100px 0 60px;
    }
  }

  @include mediaMin1240 {
    padding: 120px 0 60px;
  }

  @include mediaMin1600 {
    padding: 135px 0 60px;
  }

  @include mediaMin1920 {
    padding: 150px 0 60px;
  }
}

.contentWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.imageLeft {
  position: absolute;
  bottom: 0;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 140%;
  }

  @include mediaMin1024 {
    width: 25%;
    left: 12.5%;
  }

  @include mediaMin1240 {
    width: 20%;
    left: 15%;
  }
}

.imageRight {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 56%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba($colorBlack, 0) 0%, rgba($colorBlack, 1) 100%);
  }

  @include mediaMin768 {
    width: 80%;
  }

  @include mediaMin1024 {
    position: absolute;
    right: 0;
    left: auto;
    transform: none;
    width: 40%;

    &::after {
      display: none;
    }
  }

  @include mediaMin1240 {
    width: 35%;
  }
}

.easterEgg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.7;
}

.content {
  position: relative;
  max-width: 320px;
  margin: 0 auto;
  padding-top: 25px;
  text-align: center;

  @include mediaMin768 {
    max-width: 75%;
    padding-top: 40px;
  }

  @include mediaMin1024 {
    max-width: 70%;
  }

  @include mediaMin1240 {
    max-width: 750px;
    padding-top: 50px;
  }

  @include mediaMin1600 {
    padding-top: 80px;
  }
}

.tagline {
  margin-bottom: 20px;
  padding-bottom: 4px;
  text-transform: uppercase;

  @include mediaMin768 {
    margin-bottom: 70px;
  }

  @include mediaMin1024 {
    margin-bottom: 20px;
  }

  @include mediaMin1240 {
    margin-bottom: 35px;
  }

  @include mediaMin1600 {
    margin-bottom: 45px;
  }

  @include mediaMin1920 {
    margin-bottom: 70px;
  }
}

.copyWrapper {
  transition: color 0.6s ease-out;

  &.isEasterEggHovered {
    color: rgba($colorWhite, 0.5);

    .highlightedText {
      &:not(.active) {
        color: rgba($colorWhite, 0.5);
      }
    }
  }
}

.copy {
  display: flex;
  flex-direction: column;
}

.underlineText {
  position: relative;
}

.underline {
  position: absolute;
  bottom: -4px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: $colorWhite;
}

.highlightedText {
  pointer-events: all;
  background: none;
  color: $colorWhite;
  transition: color 0.6s ease-out;

  &.isNumber {
    font-family: Spectral, serif;
    font-weight: 500;
    font-style: italic;
  }

  > * {
    pointer-events: none;
  }

  @include device-mobile {
    filter: drop-shadow(0 0 4px rgba($colorWhite, 1));
  }

  @include device-desktop {
    animation: highlight 1s ease-in infinite alternate;
  }
}

.dots {
  margin-bottom: 10px;

  @include mediaMin768 {
    margin-bottom: 20px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mediaMin768 {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.mobileCta {
  pointer-events: all;
}

.isNumber {
  font-family: Spectral, serif;
  font-weight: 500;
  font-style: italic;
}

.exploreLink {
  position: relative;
  margin-top: 10px;
  pointer-events: all;

  > * {
    pointer-events: none;
  }

  @include device-desktop {
    animation: vertical-bounce 0.8s ease-out infinite;
    margin-top: 0;
  }

  @include mediaMin768 {
    padding: 15px 15px 0;
  }
}

.icon {
  width: 20px;
  height: 25px;
  display: block;
  background: url(../../../../assets/svg/scroll-arrow.svg) no-repeat;
  background-position: center;
  background-size: contain;

  @include mediaMin1240 {
    width: 26px;
    height: 30px;
  }
}
