@import '../../../../style/global.scss';

.trailer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  color: $colorWhite;
  z-index: 0;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    height: 100%;
    background: url('../../../../assets/images/landing/trailer/background-hidden.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    pointer-events: none;
    opacity: 0.5;
  }

  .maskedImageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  .mobileCta {
    position: absolute;
    bottom: 18%;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
  }
}
