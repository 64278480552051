.selectorCopy {
  font-family: Cormorant, serif;
  font-style: italic;
  color: white;
  text-align: center;
  margin: 0 auto;
  position: absolute;
  width: 100%;
  bottom: 140px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 20px;
  pointer-events: none;
}

.mapMainWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  &.Westside {
    left: 3%;
  }

  &.isSliderOpen {
    &.Downtown {
      top: -150px;
    }

    &.Midtown {
      top: -60px;
    }

    &.Westside {
      top: -10px;
    }

    &.Uptown {
      top: -100px;
    }
  }
}

.mapMain {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;

  .hotspots {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .bgMap {
    // width: 100%;
    // height: 100%;
    // object-fit: cover;

    position: absolute;

    &.Downtown {
      // width: 300%;
      // height: auto;
      // left: -60%;
      // bottom: 10%;

      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      object-fit: cover;
    }

    &.Midtown {
      width: 300%;
      height: auto;
      left: -70%;
      bottom: 8%;
    }

    &.Westside {
      width: 290%;
      height: auto;
      left: -40%;
      bottom: 11%;
    }

    &.Uptown {
      width: 290%;
      height: auto;
      left: -30%;
      bottom: 11%;
    }
  }
}

.bgWrapper {
  background: url('../../../assets/images/map/bg_modal_mobile.jpg') no-repeat;
  background-size: 100% auto;
  height: 75px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  &.expanded {
    background-size: 100% 100%;
    height: 200px;
  }
}

.mapInformation {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 3%;

  .lotation {
    flex: 1;
    display: flex;
  }

  .references {
    flex: 1;
    display: flex;
    flex-direction: row;

    ul {
      display: flex;
      flex-direction: row;
    }
  }
}

.hotspotSelectorWrapper {
  opacity: 0;
  position: absolute;
  bottom: 108px;
  left: 0;
  width: 100%;
  height: 135px;
  display: none;
  pointer-events: none;

  &::before {
    content: '';
    background: url('../../../assets/images/map/compass.png') no-repeat;
    background-size: 100% auto;
    position: absolute;
    left: calc(50% - 2px);
    bottom: 0;
    width: 25px;
    height: 25px;
    transform: translate(-50%, 50%);
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: repeating-linear-gradient(
      -90deg,
      rgba(black, 0.3) 1px,
      rgba(black, 0.3) 42%,
      transparent 1px,
      transparent 58%
    );
  }

  &.isVisible {
    opacity: 1;
    display: block;
    pointer-events: all;
  }
}
