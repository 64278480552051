@import '../../style/global';

.rotateYourDevice {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  color: $colorWhite;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.6s ease-in, visibility 0.6s ease-in;

  @include device-phone {
    @media (min-aspect-ratio: 3/2) {
      visibility: visible;
      opacity: 1;
    }
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../../assets/images/rotate-your-device/background.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}

.line {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 100%;
  background-color: $colorWhite;
  opacity: 0.15;
  pointer-events: none;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  display: block;
  z-index: 1;
  width: 150px;
  height: 150px;
  background: url(../../assets/images/rotate-your-device/icon.png) no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}

.copy {
  font-family: Roboto Mono, sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 1.2;
  text-align: center;
}
