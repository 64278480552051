@import '../../style/global';

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.videoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.poster {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  pointer-events: all;
  opacity: 0;
  transition: opacity 1000ms ease-in-out;

  &.show {
    opacity: 1;
  }
}

.maskedPoster {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0;
  transition: opacity 1000ms ease-in-out;

  &.show {
    opacity: 1;
  }

  &:not(.show) {
    * {
      pointer-events: none !important;
    }
  }
}

.closeButton {
  position: absolute;
  bottom: 12.5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  padding-bottom: 2px;
  font-style: italic;
  color: $colorWhite;
  pointer-events: none;
  opacity: 0;
  transition: opacity 1000ms ease-in-out;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
  }

  &.isActive {
    pointer-events: all;
    opacity: 1;
  }
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;

  &.isActive {
    display: block;
  }

  .isPlaying {
    z-index: 12;
  }
}

.controls {
  position: absolute;
  bottom: 13%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  width: 92%;
  pointer-events: all;

  opacity: 0;
  transition: opacity 250ms ease-in-out;

  &.show {
    opacity: 1;
  }
}

.button {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  pointer-events: all;

  > * {
    pointer-events: none;
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
}

.border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.progressAndTitle {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.line {
  position: relative;
  width: 28%;
  height: 1px;
  background-color: $colorWhite;
}

.progressBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 1px;
  background-color: $colorPrimaryMagenta;
  transition: transform 0.4s ease-out;
}

.title {
  margin-left: 15px;
  color: $colorWhite;
}

.durationAndVolume {
  display: flex;
  align-items: center;
}

.duration {
  margin-right: 15px;
  color: $colorWhite;
}

.volume {
  position: relative;
  width: 50px;
  height: 10px;
  overflow: hidden;
}

.volumeTotal {
  width: 100%;
  height: 100%;
  display: block;
  background: repeating-linear-gradient(
    to right,
    rgba($colorWhite, 0.3),
    rgba($colorWhite, 0.3) 1px,
    rgba($colorWhite, 0) 2px,
    rgba($colorWhite, 0) 5px
  );
}

.volumeCurrent {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  width: 100%;
  height: 100%;
  display: block;
  background: repeating-linear-gradient(
    to right,
    rgba($colorWhite, 1),
    rgba($colorWhite, 1) 1px,
    rgba($colorWhite, 0) 2px,
    rgba($colorWhite, 0) 5px
  );
  transition: transform 0.4s ease-out;
  pointer-events: none;
}

.playOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
}

.mobileOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba($colorBlack, 0) 0%, rgba($colorBlack, 0.7) 100%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms ease-in-out;

  &.isActive {
    opacity: 1;
    pointer-events: all;
  }
}

.circleButton {
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms ease-in-out;

  &.isVisible {
    opacity: 1;
    pointer-events: all;
  }

  &.defaultCircleButton {
    z-index: 5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
