@import '../../../../style/global.scss';

.locationSelector {
  position: absolute;
  width: 100%;
  bottom: 35px;
  background: url('../../../../assets/images/map/bg_modal_mobile.jpg');
  background-size: 100% auto;

  &.isActive {
    background-position: 0 -100px;
  }

  ul {
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 10px;
    margin: 0;

    li {
      flex: 1;
      text-align: center;
      font-family: Roboto Mono, sans-serif;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1px;
      color: black;

      &.isActive {
        color: $colorPrimaryMagenta;

        span {
          position: relative;

          i {
            font-style: normal;
          }

          &::after {
            content: '';
            width: 100%;
            background: currentColor;
            height: 1px;
            position: absolute;
            bottom: -6px;
            left: 0;
          }
        }
      }
    }
  }
}
