@import '../../../style/global.scss';

.mapMain {
  // background: url('../../../assets/images/map/bg_map_4.jpg') no-repeat;
  background-size: cover;
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  perspective: 200px;
  perspective-origin: center center;

  .hotspots {
    pointer-events: none;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
  }

  .bgMap {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    transform-origin: top center;
  }
}
