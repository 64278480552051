@import '../../../style/global.scss';

.emailSignUpWrapper {
  width: 100%;

  @include mediaMin480 {
    width: auto;
  }

  .loading-animation-container {
    display: none;
  }

  .components-container {
    overflow: inherit !important;
  }

  .DividerComponent {
    display: none;
  }

  .ExperienceCampaign {
    .component {
      padding: 0;
    }

    .field-label {
      text-transform: uppercase;

      .label-html {
        width: 100%;
        color: $colorBlack !important;

        @media (max-width: 320px) {
          font-size: 13px;
        }
      }
    }

    .field-border {
      border: none !important;
    }

    .SignUpFormField {
      margin: 0;
      padding-right: 10px;

      @include mediaMin480 {
        padding-right: 15px;
      }

      input[type='text'] {
        width: 100%;
        height: 35px;
        padding: 0;
        text-transform: uppercase;
        white-space: nowrap;

        &.field-color {
          color: $colorBlack !important;
        }

        @include mediaMin768 {
          width: 320px;
        }
      }

      .field-errors {
        position: absolute;
        left: 0;
        bottom: -25px;
        text-transform: uppercase;
        color: $colorBlack;
      }
    }

    .SignUpForm .sign-up-submit {
      width: auto;
      margin: 0;
      padding: 0 0 2px 10px !important;
      outline: none;
      border: none;
      border-left: 1px solid $colorBlack;
      text-transform: uppercase;
      color: $colorBlack !important;

      @media (max-width: 320px) {
        font-size: 13px;
      }

      @include mediaMin480 {
        padding: 0 0 2px 15px !important;
      }
    }
  }

  .sign-up-form {
    display: flex;
    align-items: flex-end;
    padding: 0 !important;
  }

  .PoweredBy {
    display: none;
  }
}
