@import '../../style/global.scss';

.siteFooter {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 8;
  width: 92%;
  height: 100%;
  color: rgba($colorWhite, 0.8);
  pointer-events: none;

  &.hasDarkMode {
    color: rgba($colorBlack, 0.8);

    .mobileBackground {
      background-color: transparent;

      &::before,
      &::after {
        background-color: transparent;
      }
    }
  }
}

.wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.mobileBackground {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: $colorBlack;
  opacity: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $colorBlack;
  }

  &::before {
    left: -100%;
  }

  &::after {
    right: -100%;
  }

  @include mediaMin768 {
    background-color: transparent;

    &::before,
    &::after {
      display: none;
    }
  }
}

.list {
  display: flex;
  justify-content: center;
  width: 100%;
  pointer-events: all;
  padding: 5px 0 10px;
  margin: 0;

  @include mediaMin768 {
    justify-content: flex-start;
    padding: 20px 0;
  }
}

.item {
  padding: 0 5px;

  &:first-of-type {
    padding-left: 0;
  }
}

.link {
  position: relative;
  pointer-events: all;
  font-size: 2.8vw;
  white-space: nowrap;
  transition: color 0.4s ease-out;
  display: inline-block !important;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    opacity: 0.8;
  }

  @media (max-width: 320px) {
    font-size: 2.8vw;
  }

  @include mediaMin480 {
    font-size: 12px;
  }

  @include mediaMin768 {
    font-size: 14px;
    line-height: 22px;
    display: block;
  }

  @include hover {
    color: $colorWhite;
  }
}

#tnt_adchoices {
  a {
    transition: color 0.4s ease-out;

    @include hover {
      color: $colorWhite;
    }
  }
}
