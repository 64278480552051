@import '../../style/global';

.backgroundLines {
  position: fixed;
  top: 0;
  z-index: 15;
  width: 92%;
  height: 100%;
  margin: 0 4%;
  pointer-events: none;
  transition: opacity 0.4s ease-out, visibility 0.4s ease-out;

  &.isHidden {
    visibility: hidden;
    opacity: 0;
  }
}

.hasDarkMode {
  opacity: 0;
}

.line {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: $colorWhite;
  opacity: 0.15;

  &:first-child {
    display: none;

    @include mediaMin768 {
      display: block;
      left: 0;
    }
  }

  &:last-child {
    display: none;

    @include mediaMin768 {
      display: block;
      right: 0;
    }
  }

  &:nth-child(2) {
    display: none;

    @include mediaMin768 {
      display: block;
      left: 25%;
    }
  }

  &:nth-child(3) {
    left: 50%;
    transform: translateX(-50%);
  }

  &:nth-child(4) {
    display: none;

    @include mediaMin768 {
      display: block;
      right: 25%;
    }
  }
}
