@import '../../../../style/global.scss';

.infoWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 25px 0;
  background: url('../../../../assets/images/map/bg_modal_mobile.jpg') no-repeat;
  background-size: 100% 100%;

  @include mediaMin480 {
    padding: 25px 30px 0;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border-bottom: 1px solid rgba(#a1a1a1, 0.8);
  padding: 0 0 20px;
  margin-bottom: 20px;

  .pager {
    font-family: Roboto Mono, sans-serif;
    color: $colorPrimaryMagenta;
    margin: 0 15px 2px 0;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 1.4;
  }

  h3 {
    /* stylelint-disable font-family-no-missing-generic-family-keyword */
    font-family: auto;
    /* stylelint-enable font-family-no-missing-generic-family-keyword */
    font-size: 24px;
  }
}

.copyWrapper {
  width: 100%;
  height: 120px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  font-family: Roboto Mono, sans-serif;
  font-size: 12px;
  line-height: 1.4;
  flex: 1;

  p {
    a {
      position: relative;
      transition: color 0.2s ease;
      pointer-events: all;

      div {
        pointer-events: none;
      }

      &:hover {
        color: $colorPrimaryMagenta !important;
      }

      &:before {
        content: '';
        background: $colorPrimaryMagenta;
        width: 0;
        height: 1px;
        position: absolute;
        bottom: -1px;
        left: 0;

        animation: lineLink 0.5s ease forwards 0.5s;

        @keyframes lineLink {
          to {
            width: 100%;
          }
        }
      }
    }
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  border-top: 1px solid rgba(#a1a1a1, 0.8);
  padding: 25px 0 15px;
  margin: 20px 0 10px;
  position: relative;

  &::after {
    content: '';
    width: 1px;
    height: 100%;
    background: rgba(#a1a1a1, 0.8);
    position: absolute;
    left: 50%;
    top: 0;
  }

  button {
    font-family: Roboto Mono, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    flex: 1;
    position: relative;
    letter-spacing: 1px;

    &::before {
      content: '';
      position: absolute;
      width: 15px;
      height: 14px;
      top: 50%;
      transform: translate(0, -50%);
      background: url('../../../../assets/images/map/arrow_left.png') center center no-repeat;
      background-size: 100% auto;
    }

    &:first-of-type {
      text-align: left;
      padding: 0 0 0 30px;

      &::before {
        left: 0;
      }
    }

    &:last-of-type {
      text-align: right;
      padding: 0 30px 0 0;

      &::before {
        right: 0;
        transform: rotate(180deg) translate(0, 50%);
      }
    }
  }
}

.mediaContent {
  position: relative;
  width: 100%;
  height: 250px;

  @include mediaMin480 {
    height: 375px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    width: 100%;
  }
}

.mobileOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(#000, 0.6) 0%, rgba(#000, 0) 100%);
  pointer-events: none;
}
