.image {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 40%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.2;
  pointer-events: none;

  &::before {
    content: '';
    display: block;
    padding-bottom: (467 / 831) * 100%; // 4:3 = (height / width) * 100%
  }

  &.homepage {
    background-image: url(../../../assets/images/navigation/homepage.jpg);
  }

  &.episodes {
    background-image: url(../../../assets/images/navigation/episodes.jpg);
  }

  &.discover {
    background-image: url(../../../assets/images/navigation/discover.jpg);
  }

  &.extras {
    background-image: url(../../../assets/images/navigation/extras.jpg);
  }
}
