@import '../../style/global';

.roundedCta {
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: $colorPrimaryMagenta;

  @media (max-width: 320px) {
    width: 100px;
    height: 100px;
  }

  @include mediaMin1024 {
    width: 130px;
    height: 130px;
  }

  @include mediaMin1240 {
    width: 180px;
    height: 180px;
  }
}

.copy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: $colorWhite;
}
