@import '../../style/global.scss';

.siteHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: $colorWhite;
  pointer-events: none;
  transition: opacity 0.5s ease 0.6s;

  &.isHidden {
    opacity: 0 !important;
    transition: opacity 0.4s ease;
  }

  &.hasDarkMode {
    color: $colorBlack;

    .menuIcon,
    .tntLogo,
    .logo,
    .line {
      background-color: $colorBlack;
    }
  }
}

.wrapper {
  position: relative;
  z-index: 10;
  width: 92%;
  height: 100%;
  margin: 0 auto;
}

.nav {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  pointer-events: none;

  @include mediaMin768 {
    padding-top: 35px;
  }
}

.menuButton {
  pointer-events: all;
  display: flex;
  align-items: center;

  @include mediaMin768 {
    padding: 15px 25px 15px 0;
  }

  p,
  span {
    pointer-events: none;
  }

  &.isHidden {
    pointer-events: none;
  }
}

.menuIcon {
  width: 32px;
  height: 20px;
  background-color: $colorWhite;
  mask-image: url(../../assets/svg/menu.svg);
  mask-repeat: no-repeat;
  transition: transform 0.5s ease-out;

  &.isNavigationOpen {
    clip-path: polygon(0 50%, 30% 0, 100% 0, 100% 50%, 100% 50%, 80% 100%, 0 100%);
    animation: rotateReveal 0.6s ease-out forwards 0.2s;
  }
}

.menuCopy {
  margin-left: 10px;
}

.logo {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  display: block;
  width: 132px;
  height: 32px;
  background-color: $colorWhite;
  mask-image: url(../../assets/svg/logo.svg);
  mask-repeat: no-repeat;
  transition: background-color 0.4s ease-out;
  pointer-events: all;

  @include mediaMin768 {
    width: 172px;
    height: 44px;
  }

  > * {
    pointer-events: none;
  }

  &.isHidden {
    pointer-events: none;
  }
}

.linkWrapper {
  display: flex;
  align-items: center;
}

.lineWrapper {
  display: block;
  width: 100px;
  height: 1px;
  overflow: hidden;
}

.line {
  display: block;
  width: 100%;
  height: 100%;
  background-color: $colorWhite;
  transition: background-color 0.4s ease-out;
}

.link {
  display: flex;
  align-items: center;
  margin-left: 15px;
  pointer-events: all;
  transition: color 0.4s ease-out;

  > * {
    pointer-events: none;
  }

  @include mediaMin768 {
    padding: 15px 25px 15px 0;
  }

  &.isHidden {
    pointer-events: none;
  }

  @include hover {
    color: $colorWhite;
  }
}

.tntLogo {
  margin-left: 15px;
  width: 30px;
  height: 30px;
  background-color: $colorWhite;
  mask-image: url(../../assets/svg/tnt-logo.svg);
  mask-repeat: no-repeat;
  transition: background-color 0.4s ease-out;

  @include mediaMin768 {
    width: 35px;
    height: 35px;
  }
}
