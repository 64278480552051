@import '../../../../style/global.scss';

.modalWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.underlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.mobileZoomCopy {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  color: $colorWhite;

  span:last-of-type {
    display: flex;
    align-items: center;
  }
}

.copyIcon {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  background: url(../../../../assets/svg/scroll-arrow.svg);
}

.closeButton {
  position: absolute;
  top: 30px;
  right: 30px;
  text-transform: uppercase;
  z-index: 10;
  letter-spacing: 1px;

  &:not(.closeButtonBlack) {
    color: $colorWhite !important;
  }

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -5px;
    left: 0;
    background: $colorPrimaryMagenta;
  }
}

.modal {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  // background: $colorBlack;
  display: none;
  color: #2f2f2f;
  z-index: 10;
  max-width: 100%;

  &.postcard {
    overflow: auto;
    overflow-x: hidden;
    max-height: 100%;
  }

  &.isVisible {
    opacity: 1;
    display: flex;
    flex-direction: column;
  }
}

@include mediaMin768 {
  .modal {
    position: absolute;
    top: calc(50% - 25px);
    left: 50%;
    max-width: 90vw;
    max-height: 83vh;
    height: auto;
    // z-index: 11;
    clip-path: ellipse(99% 124% at 10% 20%);
    transform-origin: center center;
    transform: translate(-50%, -50%);
    animation: enter 1s ease-out forwards 0.3s;
    overflow: auto;
    overflow-x: hidden;
    width: 800px;

    &:not(.postcard) {
      // overflow: hidden;

      &::before {
        content: '';
        width: 130px;
        height: 130px;
        position: absolute;
        z-index: 5;
        top: 41%;
        right: 4%;
        background: url('../../../../assets/images/map/stamp.png') no-repeat;
        background-size: 100% auto;
        opacity: 0;
        animation: stamp 0.2s ease-in-out forwards 2s;
        pointer-events: none;
      }

      @keyframes stamp {
        from {
          opacity: 0;
          transform: scale(1.3);
        }
        to {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    &.postcard {
      background: url('../../../../assets/images/postcards/modal_postcard_bg.jpg') no-repeat;
      background-position: center bottom;
      background-size: 100% auto;
      max-height: 90vh;
      height: auto;
    }
  }
}

@keyframes enter {
  0% {
    top: calc(50% - 25px);
    clip-path: ellipse(99% 124% at 10% 20%);
  }
  10% {
    top: 50%;
    clip-path: ellipse(100% 130% at 10% 20%);
  }
  100% {
    top: 50%;
    clip-path: ellipse(135% 130% at 10% 20%);
  }
}
