@import '../../style/global';

.postcardWrapper {
  width: 600px;
  height: 342px;
  background-image: url('../../assets/images/postcards/postcard_bg.png');
  background-size: cover;
  display: flex;
  flex-direction: row;
  position: relative;

  &::after {
    content: '';
    border: 1px solid rgba(black, 0.3);
    position: absolute;
    top: 6px;
    left: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    pointer-events: none;
    border-radius: 2px;
  }

  .location {
    font-family: Roboto Mono, sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    color: #282828;
    position: absolute;
    top: 10px;
    left: 30px;
    z-index: 1;
  }

  .stamp {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 30px;
    right: 30px;
    border: 1px solid rgba(black, 0.3);

    &::before {
      content: '';
      width: 110px;
      height: 110px;
      position: absolute;
      z-index: 5;
      top: -5px;
      right: -9px;
      background: url('../../assets/images/map/stamp.png') no-repeat;
      background-size: 100% auto;
      pointer-events: none;
    }
  }

  .imageWrapper {
    position: relative;
    width: 230px;
    height: 310px;
    margin: 16px 0 0 16px;
    padding: 0 14px 0 0;
    overflow: hidden;
    border-radius: 2px;
    box-sizing: content-box;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      background: rgba(black, 0.3);
      width: 1px;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .postcardSelectorWrapper {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    .button {
      height: 16px;
      width: 16px;
      margin: 0 10px;
      background: url('../../assets/images/map/dots.png') right center no-repeat;
      background-size: auto 16px;
      text-indent: -9999px;
      filter: invert(1);

      &.isActive {
        background-position: left center;
      }
    }
  }

  .right {
    flex: 1;
    padding: 100px 17px 0;

    .from,
    .to {
      display: flex;
      flex-direction: row;
      line-height: 20px;

      .label {
        font-family: Cormorant, serif;
        font-style: italic;
        font-weight: bold;
        margin-right: 6px;
        font-size: 14px;
      }

      .name {
        font-family: Roboto Mono, sans-serif;
        font-size: 11px;
      }
    }

    .title {
      font-family: Cormorant, serif;
      text-transform: uppercase;
      font-size: 34px;
      font-weight: bold;
      margin-top: 30px;
    }

    .caption {
      font-family: Cormorant, serif;
      font-style: italic;
      font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      padding: 0 0 0 60px;
      position: relative;

      &::before {
        content: '';
        height: 1px;
        width: 55px;
        background: black;
        position: absolute;
        top: 73%;
        left: 0;
      }
    }

    .copy {
      font-family: Roboto Mono, sans-serif;
      font-size: 11px;
      line-height: 20px;
      margin: 15px 0 0;
    }
  }

  .zoomedCopy {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3vh;
    bottom: 0;
    left: 0;
    font-family: Roboto Mono, sans-serif;
    font-size: 2.4vh;
    line-height: 5vh;
    margin: 4vh;
    z-index: 10;

    background-image: url('../../assets/images/postcards/postcard_bg.png');
    background-size: cover;
  }
}
