@import '../../../../style/global.scss';

.hotspotMarker {
  display: block;
  position: absolute;
  pointer-events: visible;
  cursor: pointer;

  &.postcardPin {
    width: 58px;
    height: 58px;
    background: black;
    border-radius: 58px;

    &::after {
      content: '';
      background: url('../../../../assets/images/map/postcard_pin.png') no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60px;
      height: 60px;
      background-size: 100% auto;
      transform: translate(-50%, -50%);
      pointer-events: none;

      mask-size: 100px auto;
      -webkit-mask-image: url('../../../../assets/images/grunge.png');
      -o-mask-image: url('../../../../assets/images/grunge.png');
      -moz-mask-image: url('../../../../assets/images/grunge.png');
      -ms-mask-image: url('../../../../assets/images/grunge.png');
      mask-image: url('../../../../assets/images/grunge.png');
    }
  }

  &.darkPin {
    width: 15px;
    height: 15px;
    background: $colorPrimaryMagenta;
    color: $colorWhite;
    border-radius: 50%;
    text-indent: -9999px;

    &::after {
      content: '';
      background: url('../../../../assets/images/map/dark_pin.png') no-repeat;
      position: absolute;
      top: calc(50% + 12px);
      left: 50%;
      width: 84px;
      height: 97px;
      background-size: 100% auto;
      transform: translate(-50%, -100%);
      pointer-events: all;
    }

    &::before {
      content: '';
      width: 50px;
      height: 50px;
      position: absolute;
      border-radius: 50%;
      background: rgba($colorPrimaryMagenta, 0.5);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: all;
    }
  }

  &:not(.darkPin):not(.postcardPin) {
    width: 20px;
    height: 20px;
    background: $colorPrimaryMagenta;
    color: $colorWhite;
    border-radius: 19px;

    &.isActive {
      text-indent: -9999px;
      background: $colorWhite;

      &::before {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        border-radius: 73px;
        background: rgba($colorPrimaryMagenta, 0.25);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        animation: enterCircle 0.4s ease forwards;

        @keyframes enterCircle {
          to {
            width: 73px;
            height: 73px;
          }
        }
      }

      &::after {
        content: '';
        background: url('../../../../assets/images/map/eye_pin.png') no-repeat;
        background-size: 100% auto;
        width: 50px;
        height: 60px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        opacity: 0;
        pointer-events: all;
        animation: enter 0.4s ease forwards 0.2s;

        @keyframes enter {
          to {
            bottom: 10px;
            opacity: 1;
          }
        }
      }
    }

    &:not(.isActive)::before {
      content: '';
      border-radius: 44px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid rgba($colorWhite, 0.8);
      animation: pulseCircle 1s ease infinite;
      pointer-events: none;
    }
  }

  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    font-family: Roboto Mono, sans-serif;
    font-size: 11px;
    line-height: 1;
    color: $colorWhite;
  }
}

@keyframes pulseCircle {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    width: 88px;
    height: 88px;
    opacity: 0;
  }
}
