@import '../../../../style/global.scss';

.hero {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  color: $colorWhite;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url('../../../../assets/images/landing/hero/background-hidden.jpg') no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.5;
  pointer-events: none;
}

.backgroundWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.backgroundOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($colorBlack, 0.4);
  pointer-events: none;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.mobileImage {
    background: url('../../../../assets/images/landing/hero/background-mobile.jpg') no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.wrapper {
  position: relative;
  z-index: 2;
  width: 92%;
  height: 100%;
  margin: 0 auto;
  padding: 120px 0 50px;
  opacity: 0;
  pointer-events: none;

  @media (max-width: 320px) {
    padding: 80px 0 50px;
  }

  @include mediaMin768 {
    padding: 100px 0 10%;
  }

  @include mediaMin1024 {
    padding: 80px 0 60px;

    @include device-mobile {
      padding: 150px 0 60px;
    }
  }

  @include mediaMin1600 {
    padding: 80px 0 60px;
  }
}

.contentWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include mediaMin768 {
    justify-content: center;
  }

  @include mediaMin1024 {
    justify-content: space-between;

    @include device-desktop {
      justify-content: center;
    }
  }

  @include mediaMin1240 {
    justify-content: center;
  }
}

.content {
  position: relative;
  width: 100%;
  text-align: center;
}

.tagline {
  margin-bottom: 40px;
  text-transform: uppercase;

  @media (max-width: 320px) {
    margin-bottom: 30px;
  }

  @include mediaMin768 {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0;
  }

  @include mediaMin1024 {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin-bottom: 30px;
  }

  @include mediaMin1600 {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0;
  }

  @include mediaMin1920 {
    top: -70px;
  }
}

.underlineText {
  position: relative;
}

.underline {
  position: absolute;
  bottom: -4px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: $colorWhite;
}

.title {
  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mediaMin768 {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.mobileCta {
  pointer-events: all;
}

.exploreLink {
  position: relative;
  margin-top: 10px;
  pointer-events: all;

  > * {
    pointer-events: none;
  }

  @include device-desktop {
    animation: vertical-bounce 0.8s ease-out infinite;
    margin-top: 0;
  }

  @include mediaMin768 {
    padding: 15px 15px 0;
  }
}

.icon {
  width: 20px;
  height: 25px;
  display: block;
  background: url(../../../../assets/svg/scroll-arrow.svg) no-repeat;
  background-position: center;
  background-size: contain;

  @include mediaMin1240 {
    width: 26px;
    height: 30px;
  }
}
