@import '../../style/global.scss';

.siteNavigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #de0f45 url(../../assets/images/texture.jpg) no-repeat center;
  background-size: cover;
  background-blend-mode: multiply;
  color: $colorBlack;
  pointer-events: all;
}

.wrapper {
  position: relative;
  width: 100%;
  padding: 0 4%;
}

.list {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 100px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include mediaMin768 {
    margin: 200px 0 100px;
  }
}

.item {
  position: relative;
  display: inline-flex;
  flex-direction: column;

  &:not(:last-of-type) {
    max-width: 90%;
    margin-bottom: 20px;

    @include mediaMin768 {
      max-width: none;
      margin-bottom: 60px;
    }
  }

  @include mediaMin1024 {
    &:nth-child(even) {
      padding-left: 10%;

      @include mediaMin1240 {
        padding-left: 20%;
      }
    }
  }
}

.subscribe {
  position: relative;
  margin-top: 10px;

  @include mediaMin768 {
    margin-top: 0;
  }
}

.mobileIcon {
  display: block;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-bottom: 3px;
  background: url('../../assets/images/navigation/scroll.png') no-repeat;
  background-position: center;
  background-size: contain;
}

.itemWrapper {
  display: inline-flex;
  flex-direction: column;
  width: 100%;

  @include mediaMin1024 {
    flex-direction: row;
  }
}

.link {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  pointer-events: all;

  > * {
    pointer-events: none;
  }

  @include mediaMin768 {
    flex-direction: row;
    align-items: center;
  }

  @include hover {
    color: $colorBlack;

    .misc {
      width: 60px;

      @include mediaMin1240 {
        width: 130px;
      }
    }

    .copy {
      transform: skewX(-5deg);
    }

    ~ .image {
      opacity: 1;
    }
  }
}

.number {
  color: $colorWhite;

  @include mediaMin768 {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.misc {
  width: 0;
  height: 4px;
  background-color: $colorBlack;
  transition: width 0.25s ease-in-out;
}

.copy {
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  line-height: 0.8;
  transform-origin: 50% 100%;
  transition: transform 0.25s ease-in-out 0.1s;

  span {
    font-family: Spectral, serif;
    font-weight: 500;
  }

  @include mediaMin768 {
    line-height: 0.7;
    margin-left: 50px;

    &:nth-child(even) {
      margin-left: 35px;
    }
  }

  @include mediaMin1240 {
    margin-left: 60px;

    &:nth-child(even) {
      margin-left: 40px;
    }
  }

  &.mobile {
    display: flex;
    align-items: flex-end;
    font-size: 28px;
  }
}

// .itemWrapper {}

.formWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include mediaMin1024 {
    padding-top: 5px;
  }

  @include mediaMin1240 {
    padding-top: 10px;
  }

  @include mediaMin1600 {
    padding-top: 20px;
  }

  @include mediaMin1920 {
    padding-top: 30px;
  }
}

.form {
  width: 100%;
  margin-top: 25px;
  border-bottom: 1px solid $colorBlack;

  @include mediaMin768 {
    width: auto;
  }

  @include mediaMin1024 {
    margin-top: 0;
    margin-left: 20px;
  }
}

.disclaimer {
  width: 100%;
  max-width: 350px;
  margin-top: 30px;
  color: $colorWhite;
  text-align: left;

  a {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $colorWhite;
    }
  }

  @include mediaMin1024 {
    margin-left: auto;
    margin-right: 0;
    text-align: right;
  }
}
