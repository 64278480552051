@import '../../../../style/global.scss';

.locationSelector {
  width: 123px;
  height: 260px;
  background: url('../../../../assets/images/map/bg_location.png') no-repeat;
  background-size: 100% auto;
  top: calc((100% - 100px) / 2);
  left: 4%;
  transform: translate(0, -50%);
  position: absolute;
  color: white;
  opacity: 0;
  animation: enter 0.7s ease forwards 1s;
  justify-content: flex-start;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 0 2px;

  .bgHorizontalLocation {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 230px;
    display: flex;
    align-items: flex-end;

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 0;
      animation: horizontalLines 0.5s ease forwards 1.5s;
    }

    @keyframes horizontalLines {
      to {
        width: 100%;
      }
    }

    &::after {
      left: 0;
      bottom: 0;
      height: 230px;
      background: repeating-linear-gradient(
        0deg,
        rgba(white, 0.4) 1px,
        rgba(white, 0.4) 2px,
        transparent 2px,
        transparent 115px
      );
    }

    &::before {
      right: 0;
      bottom: 58px;
      height: calc(230px / 2);
      border-top: 1px solid rgba(white, 0.5);
      border-bottom: 1px solid rgba(white, 0.5);
    }
  }

  .bgVerticalLocation {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 230px;

    &::after,
    &::before {
      content: '';
      position: absolute;
      height: 0;
      width: 1px;
      background: rgba(white, 0.4);
      animation: verticalLines 0.6s ease forwards 2s;
    }

    &::after {
      bottom: 0;
      left: 0;
    }

    &::before {
      top: 0;
      right: 0;
    }

    @keyframes verticalLines {
      to {
        height: 100%;
      }
    }
  }

  .indicator {
    position: absolute;
    top: -20px;
    left: 0;
    transform: translate(0, -100%);

    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      right: -13px;
      top: calc(50% + 1px);
      transform: translate(100%, -50%);
      height: 27px;
      width: 13px;
      background: url('../../../../assets/images/map/scroll_ind.png') no-repeat;
      background-size: 100% auto;
      opacity: 0;
      animation: enter 0.3s ease forwards 1s;

      @keyframes enter {
        to {
          opacity: 1;
        }
      }
    }

    .indicatorCopy {
      font-family: Roboto Mono, sans-serif;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 20px;
    }
  }

  .itemWrapper {
    width: 100%;
    height: 56px;
    margin-top: 1px;
    position: relative;
    display: block;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.2s ease;
      opacity: 0;
      background: rgba($colorPrimaryMagenta, 0.1);
    }

    &:hover {
      &::before {
        opacity: 1;
      }

      .item {
        .link {
          span {
            opacity: 1;
            padding: 0 0 0 60px;

            &::after {
              width: 35px;
              left: 20px;
            }
          }
        }
      }
    }

    &.isActive {
      pointer-events: none;

      &::before {
        opacity: 1;
      }

      .link {
        &::after {
          width: 6px;
          height: 6px;
          background: $colorPrimaryMagenta;
        }

        &::before {
          width: 30px;
          height: 30px;
        }

        span {
          opacity: 1;
          padding: 0 0 0 60px;

          &::after {
            width: 35px;
            left: 20px;
          }
        }
      }
    }
  }

  .item {
    position: absolute;

    .link {
      width: 15px;
      height: 15px;
      position: relative;

      &::after {
        content: '';
        width: 5px;
        height: 5px;
        background: white;
        position: absolute;
        top: 50%;
        left: 0;
        border-radius: 5px;
        transform: translate(-50%, -50%);
        transition: color 0.2s ease;
        opacity: 0;
        animation: enter 0.3s ease forwards;
      }

      &::before {
        content: '';
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 50%;
        background: rgba(white, 0.2);
        transform: translate(-50%, -50%);
        border-radius: 15px;
        transition: width 0.3s ease, height 0.3s ease;
        opacity: 0;
        animation: enter 0.3s ease forwards;
      }

      span {
        font-family: Cormorant, serif;
        font-size: 16px;
        font-weight: 600;
        padding: 0 0 0 40px;
        top: -3px;
        position: relative;
        white-space: nowrap;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease, padding 0.2s ease;

        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 1px;
          background: white;
          top: 50%;
          left: 0;
          transition: width 0.2s ease, left 0.2s ease;
        }
      }
    }

    .locationNameWrapper {
      opacity: 0;
      animation: enter 0.5s ease forwards 3.4s;
    }

    &.Downtown {
      top: 23%;
      left: 29.5%;

      .link::before,
      .link::after {
        animation-delay: 2.8s;
      }
    }

    &.Westside {
      top: 43%;
      left: 38.3%;

      .link::before,
      .link::after {
        animation-delay: 2.3s;
      }
    }

    &.Midtown {
      bottom: 32%;
      left: 36%;

      .link::before,
      .link::after {
        animation-delay: 2.5s;
      }
    }

    &.Uptown {
      bottom: 43%;
      left: 54.8%;

      .link::before,
      .link::after {
        animation-delay: 1.7s;
      }
    }
  }

  .locationPath {
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: -1;

    path {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: dash 1s ease-in-out forwards 2.5s;
    }

    @keyframes dash {
      to {
        stroke-dashoffset: 0;
      }
    }
  }

  .navButton {
    position: absolute;
    height: 20px;
    width: 20px;

    &.prevButton {
      top: 0;
      right: -29px;
    }

    &.nextButton {
      bottom: 0;
      right: -29px;
    }
  }
}
