@import '../../../../style/global';

.postcardMakerWrapper {
  .postcardWrapper {
    position: absolute;
    top: 190px;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);
    z-index: 3;

    @include mediaMin768 {
      top: 260px;
      transform: translate(-50%, -50%);
    }
  }

  .backgroundImageWrapper {
    position: relative;

    img {
      position: relative;
      z-index: 1;
    }
  }

  .mobileOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(#000, 0.6) 0%, rgba(#000, 0) 100%);
    pointer-events: none;
  }

  .selectorButtonWrapper {
    position: absolute;
    top: 316px;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
  }

  .postcardSelectorButton {
    height: 16px;
    width: 16px;
    margin: 0 10px;
    background: url('../../../../assets/images/map/dots.png') right center no-repeat;
    background-size: auto 16px;
    text-indent: -9999px;

    &.isActive {
      background-position: left center;
    }
  }
}

.infoWrapper {
  padding: 150px 20px 0;
  background: url('../../../../assets/images/postcards/modal_postcard_bg.jpg') no-repeat;
  background-position: center bottom;
  background-size: 100% 100%;
  height: auto;

  @include mediaMin768 {
    padding: 70px 30px 30px;
    background-size: 100% auto;
  }

  .titleWrapper {
    .title {
      font-family: Cormorant, serif;
      font-weight: 600;
      position: relative;
      font-size: 6.9vw;
      padding: 20px 0;

      @include mediaMin768 {
        letter-spacing: -1px;
        font-size: 53px;
        padding: 0 0 20px 94px;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          background: url('../../../../assets/images/postcards/postcard_icon.png') no-repeat;
          background-size: 100% auto;
          left: 10px;
          width: 50px;
          height: 60px;
          transform: translate(0, -50%);
        }
      }

      &::before {
        content: '';
        height: 1px;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: row;

    .caption {
      transform: rotate(-90deg) translate(-100%, 0);
      transform-origin: left top;
      position: relative;
      left: 0;
      top: 30px;
      height: 60px;
      align-items: center;
      font-family: Roboto Mono, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      display: none;

      @include mediaMin768 {
        display: flex;
      }
    }
  }

  .formWrapper {
    padding: 30px 0;
    width: 100%;

    @include mediaMin768 {
      padding: 30px 30px 40px;
      flex: 1;
      border-left: 1px solid rgba(0, 0, 0, 0.5);
    }

    .copy {
      font-family: Roboto Mono, sans-serif;
      font-size: 12px;
    }

    .inputsWrapper {
      margin: 20px 0 0;
      width: 100%;
      display: flex;
      flex-direction: column;

      @include mediaMin768 {
        margin: 40px 0 0;
        flex-direction: row;
      }
    }

    .inputWrapper {
      display: flex;
      flex-direction: row;
      flex: 1;

      &:first-of-type {
        margin: 0 0 15px;

        @include mediaMin768 {
          margin: 0 70px 0 0;
        }
      }

      label {
        font-family: Roboto Mono, sans-serif;
        font-size: 12px;
        text-transform: uppercase;
        margin: 0 10px 0 0;
        line-height: 24px;
        letter-spacing: 1px;
        width: 45px;

        @include mediaMin768 {
          width: auto;
        }
      }

      input {
        flex: 1;
        font-family: Roboto Mono, sans-serif;
        font-size: 12px;
        background: transparent;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        line-height: 24px;
        letter-spacing: 1px;
      }
    }

    .inputError {
      border-bottom: 1px solid $colorPrimaryMagenta !important;
    }
  }

  .shareWrapper {
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    padding: 3vh 0 30px;
    text-align: right;
    position: relative;

    @include mediaMin768 {
      padding: 30px 0 10px;
    }

    &::before {
      content: '';
      width: 16px;
      height: 30px;
      position: absolute;
      top: 28px;
      left: 0;
      background: url('../../../../assets/images/postcards/icon_copy.png') no-repeat;
      background-size: 100% auto;

      @include mediaMin768 {
        top: 28px;
      }
    }

    input {
      // display: none;
      opacity: 0;
      position: absolute;
      pointer-events: none;
    }

    button,
    span {
      font-family: Roboto Mono, sans-serif;
      font-size: 12px;
      text-transform: uppercase;

      span {
        color: $colorPrimaryMagenta;
      }
    }

    .buttonWrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: calc(100% - 3vh);
      margin: 0 0 0 3vh;
      overflow: hidden;
    }

    button {
      position: relative;

      &::before {
        content: '';
        height: 1px;
        position: absolute;
        width: 100%;
        left: calc(-100% - 10px);
        top: 50%;
        flex: 1;
        display: inline-block;
        background: rgba(black, 0.3);
      }
    }
  }
}

.copiedCopy {
  position: absolute;
  color: white;
  background: #282828;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-left: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
  text-align: center;

  &.isActive {
    opacity: 1;
  }

  span {
    font-family: Cormorant, serif;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
  }

  @include mediaMin768 {
    width: 270px;
    height: 270px;

    span {
      font-size: 22px;
      line-height: 30px;
    }
  }
}
