*,
::before,
::after {
  box-sizing: border-box;
  -webkit-user-drag: none;
  margin: 0;
  padding: 0;
}

html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  overflow: hidden;
}

:focus {
  outline: none;
}

figure {
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

svg {
  display: block;
}

button {
  background-color: transparent;
  cursor: pointer;
}

li {
  list-style-type: none;
}

address {
  font-style: normal;
}

textarea {
  resize: none;
}

input,
textarea,
button {
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  appearance: none;
}

input,
textarea,
select:required {
  box-shadow: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  color: #fff !important;
  -webkit-box-shadow: 0 0 0 30px white inset;
  background-color: transparent;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select::-ms-expand {
  display: none;
}

input[type='search'] {
  appearance: none;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

a,
span {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: normal;
}

a,
button {
  color: currentColor;
}

p {
  margin-bottom: 0;
}

::selection {
  background: rgba(#de1045, 0.5);
}

// TNT
#block-ten-tenfooter,
.global-nav {
  display: none !important;
}

html [type='button'] {
  -webkit-appearance: none !important;
}

a:hover {
  color: inherit !important;
}
