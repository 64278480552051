@import '../../../../style/global.scss';

.hotspotSelector {
  .selectorWrapper {
    overflow: hidden;
    width: 100%;
    display: flex;
    background: url('../../../../assets/images/map/bg_modal_mobile.jpg');
    background-size: 100% auto;
    height: 135px;
    position: absolute;
  }

  .selectorContainer {
    position: absolute;
    display: flex;
    flex-direction: row;
    // transition: ease-in-out 0.25s;
  }

  .hotspotItem {
    width: calc((100vw - 40px) / 3);
    margin: 0 10px;

    span {
      font-family: Roboto Mono, sans-serif;
      margin: 8px 0 6px;
      color: #262626;
      display: block;
    }

    &.isActive {
      button::before {
        width: 50%;
      }

      span {
        color: $colorPrimaryMagenta;
      }

      .imgWrapper::after {
        opacity: 1;
      }
    }

    button {
      width: 100%;
      height: 100%;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -15px;
        width: 0;
        background: $colorPrimaryMagenta;
        height: 1px;
        transition: width 0.2s ease;
      }
    }

    .imgWrapper {
      position: relative;
      overflow: hidden;
      border-radius: 6px;
      height: calc((100vw - 40px) / 3 * 0.6);
      box-shadow: 1px 6px 15px 0px rgba(#000000, 0.5);

      &::after {
        content: '';
        background: white;
        width: 13px;
        height: 13px;
        border-radius: 7px;
        position: absolute;
        top: 7px;
        left: 7px;
        opacity: 0.4;
        transition: opacity 0.2s ease;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.hotspotSelectorWrapper {
  opacity: 0;
  position: absolute;
  bottom: 110px;
  left: 0;
  width: 100%;
  height: 135px;
  transition: opacity 1s 1s;
  pointer-events: none;

  &::before {
    content: '';
    background: url('../../../../assets/images/map/compass.png') no-repeat;
    background-size: 100% auto;
    position: absolute;
    left: calc(50% - 2px);
    bottom: 0;
    width: 25px;
    height: 25px;
    transform: translate(-50%, 50%);
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: repeating-linear-gradient(
      -90deg,
      rgba(black, 0.3) 1px,
      rgba(black, 0.3) 42%,
      transparent 1px,
      transparent 58%
    );
  }

  &.isVisible {
    opacity: 1;
    display: block;
    pointer-events: all;
  }
}
