@import '../../../../style/global.scss';

.mapInformation {
  font-family: Cormorant, serif;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 92%;
  margin: 0 4%;
  padding: 1.5% 0 3.2%;

  // border-top: 1px solid rgba(white, 0.7);
  text-align: left;
  color: #eeeded;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: rgba(white, 0.7);

    animation: lineToRight 0.5s ease forwards 2s;
  }

  @keyframes lineToRight {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  .mainTitle {
    padding: 0 2% 0 0;

    justify-content: center;
    display: flex;
    flex-direction: column;

    h2 {
      font-family: Cormorant, serif;
      font-weight: 700;
      font-size: 35px;
      font-style: italic;
      text-transform: none;

      @include mediaMin1240 {
        font-size: 50px;
      }

      span {
        text-transform: uppercase;
        font-style: normal;
      }
    }

    .year {
      font-size: 24px;
      font-weight: 700;
      font-style: italic;
      position: relative;
      padding: 0 0 0 70px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        height: 1px;
        background: white;
        animation: yearLine 0.2s ease-in forwards 1.5s;
      }

      @keyframes yearLine {
        from {
          width: 0;
        }
        to {
          width: 60px;
        }
      }
    }
  }

  .location {
    flex: 1;
    display: flex;
    padding: 0 2%;
    position: relative;
    align-items: flex-start;
    flex-direction: column;

    @include mediaMin1600 {
      align-items: center;
      flex-direction: row;
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 0;
      background: rgba(white, 0.7);
      animation: linesAnimation 0.5s ease forwards 1.2s;
    }

    &::after {
      top: 0;
      left: 0;
    }

    &::before {
      bottom: 0;
      right: 0;
    }

    @keyframes linesAnimation {
      from {
        height: 0;
      }
      to {
        height: 100%;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 6px 0 0;
      font-weight: 700;
      font-family: Cormorant, serif;

      span {
        font-family: Roboto Mono, sans-serif;
        font-size: 12px;
        text-transform: uppercase;
      }

      .number {
        text-transform: none;
      }

      h3 {
        margin: 2px 0 0;
        font-weight: 700;
        font-size: 30px;
        font-family: Cormorant, serif;

        @include mediaMin1240 {
          font-size: 38px;
        }
      }
    }

    .descWrapper {
      flex: 2;
    }

    p {
      font-family: Roboto Mono, sans-serif;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .references {
    flex: 1;
    display: flex;
    padding: 0 0 0 2%;

    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @include mediaMin1600 {
      flex-direction: row;
      align-items: center;
    }

    .caption {
      position: relative;
      padding: 0 0 0 120px;
      margin: 0 0 20px;

      @include mediaMin1600 {
        margin: 0;
        flex: 1;
      }

      span {
        left: -10px;
        opacity: 0;
        position: relative;
        animation: captionAnimation 0.4s ease forwards 2.5s;
      }

      &::before {
        content: '';
        height: 1px;
        background: white;
        position: absolute;
        top: 50%;
        left: 0;
        animation: lineAnimation 0.3s ease forwards 2.3s;
      }

      @keyframes captionAnimation {
        from {
          opacity: 0;
          left: -10px;
        }
        to {
          opacity: 1;
          left: 0;
        }
      }

      @keyframes lineAnimation {
        from {
          width: 0;
        }
        to {
          width: 110px;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0 0 20px;
      margin: 0;

      @include mediaMin1600 {
        padding: 0;
      }

      li {
        position: relative;
        opacity: 0;
        animation: refIn 2s ease forwards;

        @keyframes refIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        &:nth-child(1) {
          animation-delay: 2s;
        }

        &:nth-child(2) {
          animation-delay: 2.2s;
        }

        &:nth-child(3) {
          animation-delay: 2.4s;
        }

        &:not(:last-of-type) {
          margin: 0 60px 0 0;
        }

        &::before {
          content: '';
          width: 28px;
          height: 28px;
          border-radius: 14px;
          position: absolute;
          left: -5px;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        &.showType {
          &::before {
            background: $colorPrimaryBlue;
          }
        }

        &.storyType {
          &::before {
            background: $colorPrimaryTurquoise;
          }
        }

        &.cityType {
          &::before {
            background: $colorPrimaryMagenta;
          }
        }
      }

      span {
        font-family: Roboto Mono, sans-serif;
        font-size: 12px;
        text-transform: uppercase;
        white-space: nowrap;
        position: relative;
        z-index: 1;
      }
    }
  }
}
