@import '../../../style/global.scss';

.extras {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  min-height: 100%;
  background: $colorWhite url(../../../assets/images/extras/bg-mobile.jpg) no-repeat center;
  background-size: cover;
  color: $colorWhite;
  pointer-events: all;
  opacity: 0;

  > * {
    pointer-events: none;
  }

  @include mediaMin768 {
    background-image: url(../../../assets/images/extras/bg.jpg);
  }
}

.borderMisc {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50px;
  background-color: $colorWhite;

  @include mediaMin768 {
    top: 0;
    bottom: inherit;
    width: 2%;
    height: 100%;
  }
}

.content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 92%;
  height: 100%;
  margin: 0 auto;
  padding: 100px 0 50px;

  @include mediaMin768 {
    padding: 150px 0 80px 40px;
    justify-content: flex-end;
  }

  @include mediaMin1024 {
    padding: 120px 0 60px 60px;
  }

  @include mediaMin1240 {
    padding: 150px 0 80px 80px;
  }
}

.mobileBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: $colorBlack;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $colorBlack;
  }

  &::before {
    left: 100%;
  }

  &::after {
    right: 100%;
  }
}

.titleWrapper {
  overflow: hidden;

  @include mediaMin768 {
    transform: rotate(-90deg) translateY(100%);
    transform-origin: 0% 100%;
  }
}

.title {
  display: flex;
  flex-direction: column;
  line-height: 0.9;
  overflow: hidden;

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 75px;
  }
}

.ctaWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mediaMin768 {
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translateY(-50%);
    animation: horizontal-bounce 0.8s ease-out infinite;
  }

  // Fix for Samsung Galaxy Tab S6
  @media screen and (max-width: 1138px) and (max-height: 544px) and (orientation: landscape) {
    top: 40%;
  }
}

.arrowLink {
  padding: 15px 10px;
  transform: rotate(90deg);
  pointer-events: all;

  > * {
    pointer-events: none;
  }

  @include mediaMin768 {
    animation: horizontal-bounce 0.8s ease-out infinite;
  }
}

.icon {
  display: block;
  width: 25px;
  height: 25px;
  background: url(../../../assets/svg/scroll-arrow.svg) no-repeat center;
  background-size: contain;
  transform: rotate(-90deg);
}
