@import '../../style/global.scss';

.maskedImage {
  pointer-events: none;
  width: 100%;
  height: 100%;

  .image {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.visible {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    &.hidden {
      z-index: 10;
      pointer-events: none;
      top: 110px;
      left: 110px;
      width: calc(100% - 220px);
      height: calc(100% - 220px);
    }
  }

  .hiddenImageWrapper {
    position: absolute;
    top: -110px;
    left: -110px;
    height: calc(100% + 220px);
    width: calc(100% + 220px);
    pointer-events: all;

    opacity: 0;

    // transition: opacity 5ms ease-in-out;

    // div[data-device='desktop'] & {
    //   &:not(:hover) {
    //     opacity: 0; // Leave this as it is even if it looks weird, is a trick to avoid a flicker issue ;)
    //   }
    // }
  }

  .hiddenImageBackgroundColor {
    position: absolute;
    height: calc(100% + 220px);
    width: calc(100% + 220px);
    left: -110px;
    top: -110px;
    background-color: #de1045;
    z-index: -1;
  }

  .hiddenMask {
    z-index: 10;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    pointer-events: none;
  }
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
