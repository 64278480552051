@import '../../../style/global.scss';

.landing {
  position: relative;
  width: 100%;
  height: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;

  &.isTransitioning {
    pointer-events: all;
  }
}

.content {
  position: relative;
  z-index: 6;
  display: flex;
  align-items: center;
  width: 92%;
  height: 100%;
  margin: 0 auto;
  pointer-events: none;
}

.pagination {
  position: fixed;
  top: 50%;
  left: 4%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -16px;
  color: $colorWhite;
  opacity: 0;
  pointer-events: all;
}

.button {
  padding: 15px 20px;

  > * {
    pointer-events: none;
  }
}

.current {
  padding: 0 0 15px;
}

.next {
  padding: 15px 0 0;
  opacity: 0.5;
}

.line {
  display: block;
  width: 15px;
  height: 1px;
  background-color: $colorWhite;
}
