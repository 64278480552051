@import '../../../../style/global';

.playerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;

  &.isActive {
    pointer-events: all;
  }
}

.backgroundCircle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 10px;
  height: 10px;
  background-color: $colorPrimaryMagenta;
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
}

.slider {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: $colorWhite url(../../../../assets/images/texture.jpg) no-repeat center;
  background-size: cover;
  background-blend-mode: multiply;
}

.mobileHeading {
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 70px;
  background-color: $colorBlack;
}

.mobileBar {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: rgba($colorBlack, 0.5);
}

.mobileThumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 3px;
  background-color: $colorPrimaryMagenta;
  transition: transform 0.4s ease-out;
}

.content {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @include mediaMin768 {
    padding: 120px 0 60px 0;
  }

  @include mediaMin1240 {
    padding: 140px 0 60px 40px;
  }

  @include mediaMin1600 {
    padding: 140px 0 60px 80px;
  }
}

.list {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  padding: 120px 8% 0;

  @include mediaMin768 {
    flex-direction: row;
    align-items: center;
    overflow: inherit;
    -webkit-overflow-scrolling: auto;
    padding: 0 4%;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 80px;
  }

  @include mediaMin768 {
    width: 72%;
    margin-right: 10%;
    margin-bottom: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @include mediaMin1024 {
    width: 70%;
  }

  @include mediaMin1240 {
    width: 65%;
  }

  @include mediaMin1600 {
    width: 60%;
  }

  // Fix for Samsung Galaxy Tab S6
  @media screen and (max-width: 1138px) and (max-height: 544px) and (orientation: landscape) {
    width: 55%;
  }
}

.mobileButton {
  position: relative;
  z-index: 1;
  margin-top: -55px;

  @media (max-width: 320px) {
    margin-top: -50px;
  }

  @include mediaMin1024 {
    margin-top: -65px;
  }
}

.bar {
  position: absolute;
  left: 50%;
  bottom: 60px;
  transform: translateX(-50%);
  overflow: hidden;
  width: 20%;
  min-width: 300px;
  height: 2px;
  background-color: rgba($colorBlack, 0.2);

  @include mediaMin768 {
    bottom: 100px;
  }

  @include mediaMin1024 {
    bottom: 60px;
  }
}

.thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px; // TO DO: replace this value with JS
  height: 2px;
  display: block;
  background-color: $colorBlack;
  transition: transform 0.4s ease-out;
}
