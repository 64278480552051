@import '../../style/global.scss';

.socialMedia {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 6;
  width: 92%;
  height: 100%;
  opacity: 0;
  pointer-events: none;

  &.hasDarkMode {
    .icon {
      background-color: $colorBlack;
    }
  }

  &.isInsideNavigation {
    position: relative;
    width: 100%;
    margin: 20px 0;

    @include mediaMin768 {
      position: fixed;
      width: 92%;
      margin: 0;
    }

    .socialList {
      position: relative;
      bottom: 0;
      right: auto;
      left: 0;
      flex-direction: row;
      align-items: center;
      pointer-events: none;

      > * {
        pointer-events: all;
      }

      @include mediaMin768 {
        bottom: calc(60px - 15px);
        position: absolute;
        right: -12px;
        flex-direction: column;
        align-items: flex-end;
      }
    }

    .socialItem {
      margin-right: 10px;
      pointer-events: all;

      @include mediaMin768 {
        margin-right: 0;
      }
    }
  }
}

.socialList {
  position: absolute;
  bottom: calc(60px - 15px);
  right: -12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  pointer-events: all;
}

.socialItem {
  padding: 5px 0;

  &:last-of-type {
    padding-bottom: 0;
  }
}

.socialLink {
  display: block;
  padding: 15px;

  > * {
    pointer-events: none;
  }
}

.icon {
  display: block;
  width: 22px;
  height: 22px;
  background-color: $colorWhite;
  mask-repeat: no-repeat;
  transition: transform 0.5s ease-out;

  &.facebook {
    width: 10px;
    mask-image: url(../../assets/svg/facebook.svg);
  }

  &.twitter {
    mask-image: url(../../assets/svg/twitter.svg);
  }

  &.instagram {
    mask-image: url(../../assets/svg/instagram.svg);
  }

  &.youtube {
    mask-image: url(../../assets/svg/youtube.svg);
  }

  @include mediaMin768 {
    width: 17px;
    height: 17px;
  }
}
